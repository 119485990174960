import React from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

const Privacy = ({ data }) => {
  const { title } = data.mdx.frontmatter

  return (
    <Layout>
      <div className="shell">
        <h1 className="legal__title">{title}<span class="dotRed">.</span></h1>
        <div className="mdx__body">
          {data.mdx && <MDXRenderer>{data.mdx.body}</MDXRenderer>}
        </div>
      </div>
    </Layout>
  )
}

export const PrivacyQuery = graphql`
  query PrivacyQuery($locale: String!) {
    mdx(
      fileAbsolutePath: { regex: "/static-pages/privacy/" }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
      }
      body
    }
  }
`

Privacy.propTypes = {
  data: PropTypes.object,
}

export default Privacy
